import { MenuItem } from "./menu.model";
import { Modules } from "../../../core/model/modules.enum";
import { Privileges } from "../../../core/model/privileges.enum";
import { AuthService } from "../../../providers/auth.service";

export const MENU: MenuItem[] = [
  {
    label: "Main",
    isTitle: true,
    module: Modules.COMMON,
  },
  {
    label: "Dashboard",
    icon: "home",
    link: "/dashboard",
    module: Modules.DASHBOARD,
    privilege: Privileges.VIEW_DASHBOARD,
    indexId: 0,
  },
  {
    label: "UserManagement",
    icon: "users",
    link: "/user-management/users/",
    module: Modules.USER,
    privilege: Privileges.VIEW_USER,
    indexId: 1,
  },
  {
    label: "Customer",
    icon: "user-plus",
    link: "/customer-management/customers/",
    module: Modules.CUSTOMER,
    privilege: Privileges.VIEW_CUSTOMER,
    indexId: 2,
  },
  {
    label: "Merchants",
    icon: "users",
    link: "/merchant-management/list-merchant/",
    module: Modules.MERCHANT,
    privilege: Privileges.VIEW_MERCHANT,
    indexId: 3,
  },
  // {
  //   label: "Params",
  //   icon: "layers",
  //   link: "/params-management/add-params/",
  //   module: Modules.USER,
  //   indexId: 3,
  // },
  {
    label: "STEAM",
    icon: "smartphone",
    module: Modules.STEAM,
    link: "/application-management/list-device/",
    indexId: 4,
    subItems: [
      {
        label: "Application",
        privilege: Privileges.VIEW_APPLICATION,
        link: "/application-management/list-application/",
        module: Modules.STEAM,
      },
      {
        label: "Edit Parameters",
        privilege: Privileges.VIEW_DEVICE,
        link: "/steam-param/list-device/",
        module: Modules.STEAM,        
      },
      {
        label: "Assets",
        privilege: Privileges.VIEW_ASSETS,
        link: "/asset-management/list-asset/",
        module: Modules.STEAM,        
      },
      {
        label: "Download Logs",
        privilege: Privileges.VIEW_DOWNLOAD_LOG,
        link: "/downloadlog-management/list-downloadlog/",
        module: Modules.STEAM,        
      },
      {
        label: "Device Alerts",
        module: Modules.STEAM,
        privilege: Privileges.VIEW_DEVICE_ALERT,
        link: "device-alert-management/list-devicealert",
        
      },
      {
        label: "Audit Logs",
        // module: Modules.STEAM,
        // privilege: Privileges.VIEW_DEVICE_ALERT,
        module: Modules.STEAM,
        privilege: Privileges.VIEW_AUDITLOGS,
        link: "app-auditlog-list/list-auditlog",
        
      },
    ],
  },
  {
    label: "Transactions",
    icon: "layers",
    link: "/transaction-management/transactions/",
    module: Modules.TRANSACTION,
    privilege: Privileges.VIEW_TRANSACTION,
    indexId: 5,
  },
  // {
  //   label: "Merchants",
  //   icon: "users",
  //   link: "/merchant-management/list-merchant/",
  //   module: Modules.MERCHANT,
  //   privilege: Privileges.VIEW_MERCHANT,
  //   indexId: 6
  // },
  {
    label: "CloudPOS",
    icon: "credit-card",
    link: "/virtual-terminal/list",
    module: Modules.CLOUD_POS,
    privilege: Privileges.VIRTUAL_PAYMENT,
    indexId: 6,
  },
  {
    label: "My Customers",
    icon: "user",
    link: "consumer-list/list",
    module: Modules.CONSUMER,
    privilege: Privileges.VIEW_CONSUMER,
    indexId: 8,
  },
  {
    label: "Batches",
    icon: "file-minus",
    link: "/batch-management/batches",
    module: Modules.BATCHES,
    privilege: Privileges.VIEW_BATCHES,
    indexId: 7,
  },
  {
    label: "My Tickets",
    icon: "inbox",
    link: "/my-tickets/my-ticket-list",
    module: Modules.MY_TICKETS,
    privilege: Privileges.VIEW_TICKET,
    indexId: 9,
  },
  {
      label: "Settings",
      icon: "settings",
      module: Modules.MERCHANT_SETTINGS,
      privilege: Privileges.ADD_PROMOTIONAL_SETTING,
      link: "/merchant-settings/settings",
      indexId: 10,
  },
  {
    label: "Settings",
    icon: "settings",
    module: Modules.ISO_SETTINGS,
    privilege: Privileges.HELP_DESK,
    link: "/customer-settings/settings",
    indexId: 11,
  },
  {
    label: "Troubleshooting",
    icon: "hard-drive",
    module: Modules.TROUBLESHOOT,
    link: "/spin-troubleshooting/spin-proxy",
    indexId: 12,
    subItems: [
      {
        label: "Spin Proxy",
        privilege: Privileges.SPIN_PROXY,
        link: "/spin-troubleshooting/spin-proxy",
        module: Modules.TROUBLESHOOT,
      }
    ],
  },
  {
    label:"Invoices",
    icon:"file-text",
    privilege: Privileges.VIEW_INVOICE,
    module: Modules.BILLING,
    link:"/billing-invoice"
  },
  {
    label:"Host Inventory",
    icon:"file-text",
    privilege: Privileges.VIEW_HOST_INVENTORY,
    module: Modules.HOST_INVENTORY,
    link:"/list-host-inventory",
    indexId: 13
  },
  {
    label:"Processor",
    icon:"file-text",
    privilege: Privileges.VIEW_PROCESSOR_LIST,
    module: Modules.PROCESSOR,
    link:"/processor-list"
  },
  {
    label: "some menu",
    icon: "users",

    subItems: [
      {
        isUsers: true,
        label: "menu",
        link: "/user-management/users/",
      },
    ],
  },
  {
    label: "S.A.F.E",
    icon: "shield",
    module: Modules.RISK_MANAGEMENT,
    indexId: 14,
    subItems: [
      {
        label: "Risk rules",
        privilege: Privileges.VIEW_RISK_DETAILS,
        link: "/risk-management/list",
        module: Modules.RISK_MANAGEMENT,
      },
      {
        label: "Templates",
        privilege: Privileges.VIEW_RISK_TEMPLATES,
        link: "/risk-management/templates",
        module: Modules.RISK_MANAGEMENT,
      }
    ]
  },
  {
    label: "Developer Central",
    icon: "code",
    module: Modules.DEVELOPER_CENTER,
    privilege: Privileges.EXTERNAL_DOCUMENT,
    link: "https://uatdocs.merchantportal.tech",
    indexId: 15,
    target:"_blank",
    isExternalURL:true,
  },
  {
    label: "Digital Ads",
    icon: "airplay",
    module: Modules.CAMPAIGN,
    subItems: [
      {
        label: "Digital Ad On Screen",
        privilege: Privileges.VIEW_CAMPAIGN,
        link: "/digital-ads-on-screen",
        module: Modules.CAMPAIGN,
        isMobileView: false
      }
    ]
  },
  // {
  //   label: 'Web Apps',
  //   isTitle: true
  // },
  // {
  //   label: 'Email',
  //   icon: 'mail',
  //   subItems: [
  //     {
  //       label: 'Inbox',
  //       link: '/apps/email/inbox',
  //     },
  //     {
  //       label: 'Read',
  //       link: '/apps/email/read'
  //     },
  //     {
  //       label: 'Compose',
  //       link: '/apps/email/compose'
  //     },
  //   ]
  // },
  // {
  //   label: 'Chat',
  //   icon: 'message-square',
  //   link: '/apps/chat',
  // },
  // {
  //   label: 'Calendar',
  //   icon: 'calendar',
  //   link: '/apps/calendar',
  //   badge: {
  //     variant: 'primary',
  //     text: 'New',
  //   }
  // },
  // {
  //   label: 'Components',
  //   isTitle: true
  // },
  // {
  //   label: 'UI Kit',
  //   icon: 'feather',
  //   subItems: [
  //     {
  //       label: 'Alerts',
  //       link: '/ui-components/alerts',
  //     },
  //     {
  //       label: 'Badges',
  //       link: '/ui-components/badges',
  //     },
  //     {
  //       label: 'Breadcrumbs',
  //       link: '/ui-components/breadcrumbs',
  //     },
  //     {
  //       label: 'Buttons',
  //       link: '/ui-components/buttons',
  //     },
  //     {
  //       label: 'Button group',
  //       link: '/ui-components/button-group',
  //     },
  //     {
  //       label: 'Cards',
  //       link: '/ui-components/cards',
  //     },
  //     {
  //       label: 'Carousel',
  //       link: '/ui-components/carousel',
  //     },
  //     {
  //       label: 'Collapse',
  //       link: '/ui-components/collapse',
  //     },
  //     {
  //       label: 'Datepicker',
  //       link: '/ui-components/datepicker',
  //     },
  //     {
  //       label: 'Dropdowns',
  //       link: '/ui-components/dropdowns',
  //     },
  //     {
  //       label: 'List group',
  //       link: '/ui-components/list-group',
  //     },
  //     {
  //       label: 'Media object',
  //       link: '/ui-components/media-object',
  //     },
  //     {
  //       label: 'Modal',
  //       link: '/ui-components/modal',
  //     },
  //     {
  //       label: 'Navs',
  //       link: '/ui-components/navs',
  //     },
  //     {
  //       label: 'Navbar',
  //       link: '/ui-components/navbar',
  //     },
  //     {
  //       label: 'Pagination',
  //       link: '/ui-components/pagination',
  //     },
  //     {
  //       label: 'Popovers',
  //       link: '/ui-components/popovers',
  //     },
  //     {
  //       label: 'Progress',
  //       link: '/ui-components/progress',
  //     },
  //     {
  //       label: 'Rating',
  //       link: '/ui-components/rating',
  //     },
  //     {
  //       label: 'Scrollbar',
  //       link: '/ui-components/scrollbar',
  //     },
  //     {
  //       label: 'Spinners',
  //       link: '/ui-components/spinners',
  //     },
  //     {
  //       label: 'Timepicker',
  //       link: '/ui-components/timepicker',
  //     },
  //     {
  //       label: 'Tooltips',
  //       link: '/ui-components/tooltips',
  //     },
  //     {
  //       label: 'Typeadhed',
  //       link: '/ui-components/typeahead',
  //     },
  //   ]
  // },
  // {
  //   label: 'Advanced UI',
  //   icon: 'anchor',
  //   subItems: [
  //     {
  //       label: 'Cropper',
  //       link: '/advanced-ui/cropper',
  //     },
  //     {
  //       label: 'Owl carousel',
  //       link: '/advanced-ui/owl-carousel',
  //     },
  //     {
  //       label: 'Sweet alert',
  //       link: '/advanced-ui/sweet-alert',
  //     },
  //   ]
  // },
  // {
  //   label: 'Forms',
  //   icon: 'file-text',
  //   subItems: [
  //     {
  //       label: 'Basic elements',
  //       link: '/form-elements/basic-elements'
  //     },
  //     {
  //       label: 'Advanced elements',
  //       subItems: [
  //         {
  //           label: 'Form validation',
  //           link: '/advanced-form-elements/form-validation'
  //         },
  //         {
  //           label: 'Input mask',
  //           link: '/advanced-form-elements/input-mask'
  //         },
  //         {
  //           label: 'Ng-select',
  //           link: '/advanced-form-elements/ng-select'
  //         },
  //         {
  //           label: 'Ngx-chips',
  //           link: '/advanced-form-elements/ngx-chips'
  //         },
  //         {
  //           label: 'Ngx-color-picker',
  //           link: '/advanced-form-elements/ngx-color-picker'
  //         },
  //         {
  //           label: 'Ngx-dropzone',
  //           link: '/advanced-form-elements/ngx-dropzone-wrapper'
  //         },
  //       ]
  //     },
  //     {
  //       label: 'Editors',
  //       link: '/form-elements/editors'
  //     },
  //     {
  //       label: 'Wizard',
  //       link: '/form-elements/wizard'
  //     },
  //   ]
  // },
  // {
  //   label: 'Charts & graphs',
  //   icon: 'pie-chart',
  //   subItems: [
  //     {
  //       label: 'ApexCharts',
  //       link: '/charts-graphs/apexcharts',
  //     },
  //     {
  //       label: 'ChartJs',
  //       link: '/charts-graphs/chartjs',
  //     },
  //   ]
  // },
  // {
  //   label: 'Tables',
  //   icon: 'layout',
  //   subItems: [
  //     {
  //       label: 'Basic tables',
  //       link: '/tables/basic-table',
  //     },
  //     {
  //       label: 'Data table',
  //       link: '/tables/data-table',
  //     },
  //     {
  //       label: 'Ngx-datatable',
  //       link: '/tables/ngx-datatable'
  //     }
  //   ]
  // },
  // {
  //   label: 'Icons',
  //   icon: 'smile',
  //   subItems: [
  //     {
  //       label: 'Feather icons',
  //       link: '/icons/feather-icons',
  //     },
  //     {
  //       label: 'Flag icons',
  //       link: '/icons/flag-icons',
  //     },
  //     {
  //       label: 'Mdi icons',
  //       link: '/icons/mdi-icons',
  //     }
  //   ]
  // },
  // {
  //   label: 'Pages',
  //   isTitle: true
  // },
  // {
  //   label: 'Special pages',
  //   icon: 'book',
  //   subItems: [
  //     {
  //       label: 'Blank page',
  //       link: '/general/blank-page',
  //     },
  //     {
  //       label: 'Faq',
  //       link: '/general/faq',
  //     },
  //     {
  //       label: 'Invoice',
  //       link: '/general/invoice',
  //     },
  //     {
  //       label: 'Profile',
  //       link: '/general/profile',
  //     },
  //     {
  //       label: 'Pricing',
  //       link: '/general/pricing',
  //     },
  //     {
  //       label: 'Timeline',
  //       link: '/general/timeline',
  //     }
  //   ]
  // },
  // {
  //   label: 'Authentication',
  //   icon: 'unlock',
  //   subItems: [
  //     {
  //       label: 'Login',
  //       link: '/auth/login',
  //     },
  //     {
  //       label: 'Register',
  //       link: '/auth/register',
  //     },
  //   ]
  // },
  // {
  //   label: 'Error',
  //   icon: 'cloud-off',
  //   subItems: [
  //     {
  //       label: '404',
  //       link: '/error/404',
  //     },
  //     {
  //       label: '500',
  //       link: '/error/500',
  //     },
  //   ]
  // },
];
