import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DatePipe, getLocaleWeekEndRange } from '@angular/common';
import { date } from 'ngx-custom-validators/src/app/date/validator';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { isEmpty } from 'lodash';


// @Pipe({
//   name: 'datePipe'
// })
// export class datePipe implements PipeTransform {
//   transform(value: Date): String {
//     return new DatePipe('en-Us').transform(value,environment.dateFormat,environment.displayTimeZone);
//   }

// }

// @Pipe({
//     name: 'dateTimePipe'
//   })
//   export class dateTimePipe implements PipeTransform {
//     transform(value: Date): String {
//       return new DatePipe('en-Us').transform(value,environment.dateTimeFormat,environment.displayTimeZone);
//     }

//   }

@Pipe({
  name: 'datePipe'
})
export class datePipe implements PipeTransform {
  transform(value: Date): String {
    if(isEmpty(value)) {
      return null;
    }
    return `${moment.tz(value, environment.displayTimeZone).format(environment.dateFormat)} (EST)`//new DatePipe('en-Us').transform(value,environment.dateFormat,environment.displayTimeZone);
  }

}

@Pipe({
    name: 'dateTimePipe'
  })
  export class dateTimePipe implements PipeTransform {
    transform(value: Date): String {
      if(isEmpty(value)) {
        return null;
      }
      return `${moment.tz(value, environment.displayTimeZone).format(environment.dateTimeFormat)} (EST)`//new DatePipe('en-Us').transform(value,environment.dateTimeFormat,environment.displayTimeZone);
    }

  }

@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {
  transform(value: Date, format: string = 'MM-DD-YYYY'): String {
    if (isEmpty(value)) {
      return null;
    }
    return moment(value).format(format);
  }
}
