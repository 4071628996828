<p-toast position="top-right"></p-toast>
<div class="modal-header d-flex ">
    <div class="row thumbs_icon">
        <div class="ml-2">
            <h5 class="modal-title" id="exampleModalLongTitle"><span
                    class="font-weight-bold text_baseline">{{header}}</span>
            </h5>
            <!-- <div class="font-weight-bold text_baseline text-right" (click)="modal.close()">X</div> -->
        </div>
    </div>
</div>
<div class="modal-body text-center px-3 py-3">

    <div *ngIf="successFlag">

        <!-- <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle class="path circle" fill="none" stroke="#10b759" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
            <polyline class="path check" fill="none" stroke="#10b759" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
        </svg> -->
        <svg style="width: 100px; height: 100px" version="1.1" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 130.2 130.2">
            <circle class="path circle" fill="none" stroke="#10b759" stroke-width="6" stroke-miterlimit="10" cx="65.1"
                cy="65.1" r="62.1" />
            <polyline class="path check" fill="none" stroke="#10b759" stroke-width="6" stroke-linecap="round"
                stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
        </svg>
    </div>
    <div *ngIf="negativeFlag">
        <svg style="width: 100px; height: 100px" version="1.1" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 130.2 130.2">
            <circle class="path circle" fill="none" stroke="#ff3366" stroke-width="6" stroke-miterlimit="10" cx="65.1"
                cy="65.1" r="62.1" />
            <line class="path line" fill="none" stroke="#ff3366" stroke-width="6" stroke-linecap="round"
                stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
            <line class="path line" fill="none" stroke="#ff3366" stroke-width="6" stroke-linecap="round"
                stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
        </svg>
    </div>
    <h5 class="text-center mt-2 mb-1">{{body}}</h5>
    <div *ngIf="negativeFlag">
        <b>Reason:</b> <span>
            <p class="text-center mt-2 mb-1">{{message}}</p>
        </span>
    </div>
      <div [ngStyle]="increaseBodyContentSize" *ngIf="innerHtmlContent != ''" [innerHTML]="innerHtmlContent">
      </div>
</div>
<div class="modal-footer pb-2">
    <div class="text-right m-1" *ngIf="okButton">
        <button (click)="modal.close();" class="mt-2 text-right btn btn-primary mr-2">OK</button>
    </div>
    <!-- <div class="text-right m-1" *ngIf="cancelButton">
    </div> -->
    <div class="text-right m-1" *ngIf="commitButton">
        <button (click)="modal.close();" class="mt-2 text-right btn btn-danger mr-2">Cancel</button>
        <button (click)="commitInvoice()" class="mt-2 text-right btn btn-primary mr-2"[disabled]="spinLoader">
            <span *ngIf="spinLoader" class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>{{commitButtonText}}</button>
    </div>
    <div class="text-right m-1" *ngIf="gotoButton">
        <button (click)="modal.close();" class="mt-2 text-right btn btn-primary mr-2">OK</button>
        <button (click)="modal.close();"class="mt-2 text-right btn btn-primary mr-2" [disabled]="disableButton"
                    [queryParams]="goToQueryParams" [routerLink]="goToRoute">{{buttonName}}
        </button>
    </div>
    <div  class="text-right m-1" *ngIf="enableISOLogo">
        <button (click)="modal.close('close');" class="text-right btn btn-danger mr-2">Cancel</button>
        <button *ngIf="!onlyCancelButton" (click)="deleteISOLogo()" class="text-right btn btn-primary mr-2" [disabled]="spinLoader">
            <span *ngIf="spinLoader" class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span> Delete</button>
    </div>
    <div class="text-right m-1" *ngIf="enable2buttons">
      <button (click)="modal.close('close');" class="text-right btn btn-danger mr-2">{{closeBtnName}}</button>
      <button (click)="modal.close('success')" class="text-right btn btn-primary mr-2">
        {{buttonName}} </button>
    </div>
    <div class="text-right m-1" *ngIf="isOpenChangeTidModal">
        <button (click)="modal.close('close');" class="text-right btn btn-danger mr-2">Cancel</button>
        <button *ngIf="isShowPartialUpdateButton" (click)="modal.close('updatePartially')" class="text-right btn btn-primary mr-2">
            <span *ngIf="spinLoader" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            {{partialUpdateButtonContent}}</button>
        <button (click)="modal.close('updateOverAll')" class="text-right btn btn-primary mr-2">
            <span *ngIf="spinLoader" class="spinner-border spinner-border-sm"
                aria-hidden="true"></span>{{overAllUpdateButtonContent}}</button>
    </div>
</div>
