import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";

import { datePipe, dateTimePipe, MomentDatePipe } from "./datePipe"; // <---

@NgModule({
  declarations: [datePipe, dateTimePipe, MomentDatePipe], // <---
  imports:[CommonModule],
  exports: [datePipe, dateTimePipe, MomentDatePipe] // <---
})

export class datePipeModule{
    static forRoot() {
        return {
            ngModule: datePipeModule,
            providers: [],
        };
     }
}